import "../Styles/Footer.css";
import mail_img from "../assets/mail.svg";
import tel_img from "../assets/phone.svg";
import linkedin_img from "../assets/linkedin.svg";
function Footer() {
  return (
    <section className="footer" id="Contact">
      <div class="ocean2">
        <div class="wave3"></div>
        <div class="wave3 wave4"></div>
      </div>
      <div className="intro">
        <h1>Omar</h1>
        <h3>
          Capturing moments and crafting stories. Reach out to bring
          your story to life
        </h3>
      </div>
      <div>
        <h1>Introduction</h1>
        <a href="#Home">
          <h3>Home</h3>
        </a>
        <a href="#About">
          <h3>About</h3>
        </a>
      </div>
      <div>
        <h1>Achievements</h1>
        <a href="#Projects">
          <h3>Projects</h3>
        </a>
        <a href="#Contact">
          <h3>Contact</h3>
        </a>
      </div>
      <div className="contact_info">
        <h1>Let's connect ! </h1>
        <div>
          <a
            href="https://www.linkedin.com/in/omar-a-3b768a236/"
            target="_blank"
          >
            <img src={linkedin_img} alt="" srcset="" />
          </a>
          <a href="mailto:omar.abounayme@gmail.com" target="_blank">
            <img src={mail_img} alt="" srcset="" />
          </a>
          <a href="tel:+33752397220" target="_blank">
            <img src={tel_img} alt="" srcset="" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Footer;
