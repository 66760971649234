import "../Styles/Navbaros.css";
import img from "../assets/menu.png";
function collapse_menu() {
  var header = document.getElementById("Header");
  var menu_collapse = header.querySelector(".menu_collapse");
  var header_img = header.querySelector("img");
  var header_computed = getComputedStyle(header);
  if (header_computed.position == "fixed") {
    if (header_computed.backgroundColor != "rgba(0, 0, 0, 0)") {
      header.style.backgroundColor = "rgba(0, 0, 0, 0)";
      header_img.style.filter = "none";
      header.style.height = "100px";
      header.style.width = "100px";
      menu_collapse.style.display = "none";
      menu_collapse.style.height = "0%";
      menu_collapse.style.width = "0%";
      menu_collapse.style.opacity = "0";
      menu_collapse.style.backgroundColor = "rgba(0, 0, 0, 0)";
      header.style.zIndex = "11";
    } else {
      header.style.zIndex = "13";
      menu_collapse.style.display = "flex";
      header_img.style.filter = "invert(100%)";
      header.style.height = "100%";
      header.style.width = "50%";
      header.style.backgroundColor = "#222831";
      menu_collapse.style.height = "100%";
      menu_collapse.style.width = "100%";
      menu_collapse.style.opacity = "1";
      setTimeout(() => {
        menu_collapse.style.backgroundColor = "#222831";
      }, 10);
    }
  }
}
function Navbaros() {
  return (
    <div className="Header" onClick={collapse_menu} id="Header">
      <img src={img} alt="" srcset="" />
      <a href="#">
        <h3>Accueil</h3>
      </a>
      <a href="#About">
        <h3>A Propos</h3>
      </a>
      <a href="#Projects">
        <h3>Projets</h3>
      </a>
      <a href="#Contact" id="contact_nav">
        <h3>Contact</h3>
      </a>
      <div className="menu_collapse">
        <a href="#">
          <h3>Accueil</h3>
        </a>
        <a href="#About">
          <h3>A Propos</h3>
        </a>
        <a href="#Projects">
          <h3>Projets</h3>
        </a>
        <a href="#Contact">
          <h3>Contact</h3>
        </a>
      </div>
    </div>
  );
}

export default Navbaros;
