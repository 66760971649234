import "./App.css";
import About from "./components/About";
import Home_main from "./components/Home_main";
import Navbaros from "./components/Navbaros";

import Footer from "./components/Footer";
import { useEffect } from "react";
import Projects_main from "./components/Projects_main";
import Skills from "./components/Skills";
function App() {
  function topus() {
    // Get the button:
    let mybutton = document.getElementById("myBtn");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        mybutton.style.opacity = "100";
      } else {
        mybutton.style.opacity = "0";
      }
    }
  }
  useEffect(() => {
    topus();
  }, []);
  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  return (
    <div className="App">
      <Navbaros />
      <Home_main />
      <About />
      <Skills />
      <Projects_main />
      <Footer />
      <button onClick={topFunction} id="myBtn" title="Go to top">
        ↑
      </button>
    </div>
  );
}

export default App;
