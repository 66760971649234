import "../Styles/Projects_main.css";
import Atropos from "atropos/react";
import { useEffect, useState } from "react";
import axios from "axios";
import Divider from "@mui/material/Divider";
import cancel_svg from "../assets/cancel.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";

function Projects_main() {
  const [Projects, setProjects] = useState([]);
  const [currentProject, setcurrentProject] = useState([]);

  var url = process.env.REACT_APP_WEBLINK;
  async function Appearos(type) {
    var test = await axios
      .get(process.env.REACT_APP_WEBLINK_FIVE + "/check/" + type)
      .then((data) => {
        if (Array.isArray(data.data)) {
          return data.data;
        } else return [];
      });
    setProjects(test);
  }
  function Overlayos(e) {
    setcurrentProject(e);
    if ((document.getElementById("Overlay").style.visibility = "hidden")) {
      document.getElementById("Overlay").style.visibility = "visible";
      document.getElementById("Overlay").style.pointerEvents = "auto";
      document.getElementById("Overlay").style.opacity = "1";

      document.body.style.overflow = "hidden";
    }
  }
  function removeOverlayos() {
    document.getElementById("Overlay").style.visibility = "hidden";
    document.getElementById("Overlay").style.pointerEvents = "none";
    document.body.style.overflow = "visible";
    document.getElementById("Overlay").style.opacity = "0";
    setTimeout(() => {
      setcurrentProject([]);
      console.log(currentProject["project"]);
    }, 10);
  }

  useEffect(() => {
    Appearos("Videos");
  }, []);
  return (
    <>
      <div className="Overlay" id="Overlay">
        <img
          src={cancel_svg}
          alt=""
          srcset=""
          id="cancel_svg"
          onClick={removeOverlayos}
        />
        <Atropos>
          <div id="data_overlay">
            <div id="data_carousel">
              {currentProject["project"] && (
                <Carousel showStatus={false} emulateTouch={true}>
                  {currentProject["project"]?.map((e, id) => {
                    if (e[1] == "videos") {
                      return (
                        <>
                          <div>
                            <video src={url + e[0]} controls></video>{" "}
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div>
                            <img
                              src={url + e[0]}
                              alt="new"
                              className="Overlay_img "
                            />
                          </div>
                        </>
                      );
                    }
                  })}
                </Carousel>
              )}
            </div>
            <div id="data_text">
              <h2>{currentProject.title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: currentProject.description }}
              ></div>
            </div>
          </div>
        </Atropos>
      </div>
      <section className="Projects_main" id="Projects">
        <div className="star"></div>

        <div class="Projects_nav">
          <h2 onClick={() => Appearos("Videos")}>Vidéos</h2>
          <Divider
            orientation="vertical"
            flexItem
            variant="middle"
            sx={{
              opacity: 1,
              backgroundColor: "white",
              height: 50,
              top: 5,
              position: "relative",
            }}
          />

          <h2 onClick={() => Appearos("Graphisme")}>Graphisme</h2>
        </div>
        <div className="Projects" data-atropos-offset="2">
          {Projects.map((e) => {
            return (
              <div onClick={() => Overlayos(e)} className="Project fade-in">
                <img src={url + e.img} alt="new" />
                <h3>{e.title}</h3>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Projects_main;
