import React from "react";
import "../Styles/Skills.css";
import Atropos from "atropos/react";

const Skills = () => {
  return (
    <section className="Skills" id="Skills">
      <div className="grain"></div>

      <h1>My Skills</h1>
      <hr />
      <br />

      <div className="Container">
        <Skill
          icon={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Adobe_Premiere_Pro_CC_icon.svg/1024px-Adobe_Premiere_Pro_CC_icon.svg.png?20210729021549"
          }
          color={"rgba(153,153,255,0.1)"}
        >
          Adobe Premiere Pro
        </Skill>

        <Skill
          icon={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/DaVinci_Resolve_17_logo.svg/2048px-DaVinci_Resolve_17_logo.svg.png"
          }
          color={"rgba(82, 136, 186, 0.1)"}
        >
          Davinci resolve
        </Skill>

        <Skill
          icon={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/1051px-Adobe_Photoshop_CC_icon.svg.png"
          }
          color={"rgba(64, 169, 255, 0.1)"}
        >
          Adobe Photoshop
        </Skill>

        <Skill
          icon={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/1024px-Adobe_Illustrator_CC_icon.svg.png?20220814183839"
          }
          color={"rgba(255, 154, 1, 0.1)"}
        >
          Adobe Illustrator
        </Skill>

        <Skill
          icon={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/939px-Blender_logo_no_text.svg.png"
          }
          color={"rgba(235, 119, 0, 0.1)"}
        >
          Blender
        </Skill>
        <Skill
          icon={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Adobe_After_Effects_CC_icon.svg/langfr-1024px-Adobe_After_Effects_CC_icon.svg.png"
          }
          color={"rgba(153,153,255,0.1)"}
        >
          Adobe After Effects
        </Skill>
      </div>
    </section>
  );
};

export default Skills;

const Skill = ({ icon, color, children }) => {
  return (
    <Atropos>
      <div className="SkillContainer">
        <div className="Icon" data-atropos-offset="5">
          <img src={icon} alt="" />
        </div>

        <div className="overlay" style={{ background: color }}>
          <div></div>
        </div>
        <div className="Text" data-atropos-offset="5">
          {children}
        </div>
      </div>
    </Atropos>
  );
};
