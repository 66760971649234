import "../Styles/About.css";
import img from "../assets/1673793663577.jpeg";
import pdf from "../assets/OmarCV.pdf";
import Atropos from "atropos/react";

function About() {
  return (
    <section className="About" id="About">
      <Atropos>
        <h1>Learn to know me !</h1>
        <hr />
        <br />
        <div className="grain"></div>
        <div className="About_container ">
          <div className="About_projects">
            <img src={img} alt="" srcset="" />
            <a href={pdf} download>
              <div className="cvox">
                <div className="grain"></div>
                Get my CV ! <span class="wave_hand">🖐️</span>
              </div>
            </a>
          </div>
          <div className="About_main" data-atropos-offset="3">
            <h2>
              "In the right light, at the right time, everything is
              extraordinary"
            </h2>
            <div>
              Passionné par l'art de la narration visuelle, je suis un monteur
              vidéo et cadreur avec une riche expérience dans divers projets
              audiovisuels. Que ce soit derrière la caméra, en salle de montage,
              ou en studio, je m'efforce de capturer et de façonner des
              histoires captivantes. Mon expertise s'étend au cadrage précis, à
              la prise de son soignée et, surtout, à la gestion de l'éclairage,
              essentielle pour créer l'ambiance parfaite.
            </div>
            <div>
              Chaque projet est pour moi une nouvelle opportunité de mettre en
              lumière des visions uniques et de collaborer avec des créateurs
              talentueux pour produire des contenus de qualité exceptionnelle.
            </div>
            <h2>"Every light has its shadow, and every shadow has a story"</h2>
            <div>
              Passionné par la vidéo, je cherche une alternance en montage et
              cadrage pour perfectionner mes compétences et contribuer à des
              projets créatifs. Avec une solide maîtrise des logiciels de
              montage et des techniques de cadrage, je suis motivé, créatif et
              rigoureux. Si vous recherchez un alternant engagé, discutons d'une
              opportunité ensemble.
            </div>
          </div>
        </div>
      </Atropos>
    </section>
  );
}

export default About;
