import "../Styles/Home_main.css";
import img from "../assets/1673793663577.jpeg";
import Atropos from "atropos/react";

function Home_main() {
  return (
    <section className="Home_main_container" id="Home">
      <Atropos>
        <div class="ocean">
          <div class="wave"></div>
          <div class="wave wave2"></div>
        </div>
        <div className="Home_main">
          <img src={img} alt="" data-atropos-offset="5" />

          <div data-atropos-offset="5">
            <h1>Hello, I'm Omar Abounayme !</h1>
            <h1>
              Happy to help ! <div className="wave_hand">👋</div>
            </h1>
          </div>
        </div>
      </Atropos>
    </section>
  );
}

export default Home_main;
